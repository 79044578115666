@import "../base/variables";

/* Portfolio SERVICES Block */

.services {
    background: $dark-color;

    &__heading p {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
        background: $less-dark-color;
    }

    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
    }
}

/* Service box */

.service-box {
    padding: 40px 20px;
    background: $less-dark-color;
    color: $light-color;
    max-width: 340px;
    margin: 20px;
    text-align: center;
    transition: 0.5s;

    @include showElementMixin(3s);

    @media (max-width: map-get($map: $breakpoints, $key: medium)) {
        margin: 10px;
    }

    &:hover {
        background: $main-color;
    }

    &__image {
        max-width: 80px;
    }

    &__header {
        font-size: 20px;
        font-weight: 600;
    }
}
