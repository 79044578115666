@import "../base/variables";

/* Portfolio ABOUT Block */

.about {
    &__content {
        flex-direction: column;
    }

    &__section {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding-bottom: 10px;
    }

    &__subheader {
        display: inline-block;
        margin-bottom: 10px;
        padding: 10px 30px;
        border: 0;
        border-radius: 10px;
        font-size: 24px;
        color: $light-color;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 2px;
        font-weight: 500;
        background: $main-color;
        cursor: pointer;
        user-select: none;
        transition: 0.3s ease-out;

        &:hover {
            filter: brightness(80%);
        }

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            font-size: 20px;
        }

        @include showElementMixin(1s);
    }

    &__content-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        border-radius: 10px;
        overflow: hidden;

        transition: all 0.35s;

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            flex-direction: column;
        }

        &--hidden {
            max-height: 0;

            transition: all 0.35s;
        }

        @include showElementMixin(2s);
    }

    &__image {
        min-width: 50%;
        padding: 10px;

        img {
            transform: scale(0.8);
            opacity: 0.7;
            border-radius: 10%;
            border: 2px solid $orange-color;
            transition: 0.3s linear;

            &:hover {
                filter: brightness(80%);
                transform: scale(0.9);
                opacity: 1;
                box-shadow: 0 0 10px black inset;
            }

            @include showElementMixin(2s);
        }

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            margin-bottom: 20px;
        }
    }

    &__text {
        margin: 10px 0;
        text-align: justify;
        text-indent: 1.5em;
        background: $light-color;
        padding: 1em;
        border-radius: 10px;
        box-shadow: $block-box-shadow;

        p {
            margin: 10px auto;

            a {
                text-decoration: none;

                &:hover {
                    filter: brightness(120%);
                }
            }
        }
    }
}

.image {
    max-width: 100%;
}
