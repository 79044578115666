@import "../base/variables";

/* Portfolio TESTIMONIAL Block */

.testimonial {
    &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        align-items: center;

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

/* Testimonial box */

.testimonial-box {
    padding: 60px 40px;
    margin: 20px;
    background: $main-color;
    color: $dark-color;
    color: $light-color;
    text-align: justify;
    border-radius: 10px;
    box-shadow: $block-box-shadow;

    @include showElementMixin(1s);

    @media (max-width: map-get($map: $breakpoints, $key: medium)) {
        padding: 40px 20px;
        margin: 10px;
    }

    &__text {
        font-style: italic;
        font-size: 1.3em;
        font-weight: 300;
    }

    &__meta {
        margin-top: 40px;
        text-align: end;
        font-weight: 600;
        font-size: 20px;
        line-height: 1em;

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            margin-top: 20px;
        }
    }
}

.meta__addition-info {
    font-size: 14px;
    font-weight: 400;
}
