@import "../base/variables";

.timeline {
    background: $dark-color;

    color: $dark-color;

    &__title {
        padding-top: 0.25em;
        letter-spacing: 2px;
        font-weight: 900;
        text-transform: uppercase;
    }

    &__date {
        color: $less-light-color;
    }

    &__description {
        margin: 1.5em 0 2em 0;
    }

    &__icon {
        color: $light-color;

        &--fantasy {
            background-color: $green-color;
        }

        &--education {
            background-color: $orange-color;
        }

        &--work {
            background: $red-color;
        }
    }

    &__button {
        text-decoration: none;
        padding: 0.5em 1em;
        border-radius: 5px;
        color: $light-color;

        &--fantasy {
            background-color: $green-color;

            &:hover {
                filter: brightness(90%);
            }
        }

        &--education {
            background-color: $orange-color;

            &:hover {
                filter: brightness(90%);
            }
        }

        &--work {
            background: $red-color;

            &:hover {
                filter: brightness(90%);
            }
        }
    }
}

.vertical-timeline-element-icon svg {
    margin-left: 0 !important;
    margin-top: 0 !important;
    transform: translate(-50%, -50%);
}

.vertical-timeline-element-content.bounce-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
