@import "../base/variables";

/* Portfolio HOME Block */

.home {
    position: relative;
    background: url($home-img-path);
    background-size: cover;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: map-get($map: $breakpoints, $key: medium)) {
        padding: 150px 50px 100px;
    }

    @media (max-width: map-get($map: $breakpoints, $key: small)) {
        padding: 150px 20px 100px;
    }

    &__text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include showElementMixin(3s);
    }

    &__header {
        font-size: 3em;
        color: $light-color;
        font-weight: 500;
        line-height: 1.5em;

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            font-size: 1.5em;
        }
    }

    &__author {
        font-size: 1.5em;
        font-weight: 700;
    }

    &__subheader {
        font-size: 1.5em;
        color: $light-color;
        font-weight: 500;
        line-height: 1.5em;

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            font-size: 1em;
        }
    }
}
