@import "../base/variables";

/* Contact FormBox */

.form-box {
    min-width: 60%;
}

.form {
    display: flex;
    flex-direction: column;

    &__header {
        color: $light-color;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    &__input,
    &__textarea {
        margin-bottom: 20px;
        padding: 15px;
        font-size: 16px;
        background: transparent;
        border: none;
        outline: none;
        background: $less-dark-color;
        color: $light-color;
        resize: none;
    }

    &__input:focus,
    &__textarea:focus {
        background-color: $less-light-color;
        color: $less-dark-color;
    }

    &__textarea {
        min-height: 200px;
    }

    &__input::placeholder,
    &__textarea::placeholder {
        color: $cream-grey-color;
    }

    &__input--submit {
        width: 50%;
        align-self: center;
        background: $main-color;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:hover,
        &:focus {
            background-color: $main-color;
            filter: brightness(80%);
        }
    }

    &__error-info {
        color: $less-light-color;
        text-align: center;
        padding: 10px;
        background: $less-dark-color;
    }

    @include showElementMixin(3s);
}
