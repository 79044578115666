@import "../base/variables";

/* Portfolio CONTACT Block */

.contact {
    background: $dark-color;

    &__heading p {
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
        background: $less-dark-color;
    }

    &__info {
        min-width: 40%;

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            min-width: 40%;
            margin: 20px 0;
        }
    }

    &__info-header {
        color: $light-color;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }
}

/* Contact InfoBox */

.info-box {
    position: relative;
    padding: 20px 0;
    display: flex;

    &__icon {
        min-width: 40px;
        padding-top: 4px;
        color: $light-color;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 24px;
    }

    &__text {
        display: flex;
        margin-left: 20px;
        font-size: 16px;
        color: $light-color;
        flex-direction: column;
        font-weight: 300;
        word-break: break-word;
    }

    &__name {
        font-weight: 500;
        color: $main-color;
    }

    &__link {
        color: $light-color;
        font-weight: 700;

        &:hover {
            filter: brightness(80%);
        }
    }
}
