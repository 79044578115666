// COLORS
$main-color: #2196f3;
$dark-color: #111;
$less-dark-color: #222;
$light-color: #fff;
$less-light-color: #dfdbe5;
$black-color: #000;
$cream-grey-color: #999;
$green-color: #06d6a0;
$orange-color: #f9c74f;
$red-color: #ac2929;

// PROPERTIES
$block-box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
0 22.3px 17.9px rgba(0, 0, 0, 0.072),
0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);

// PATHS
$toggle-open-path: "../../assets/menu/open.png";
$toggle-open-path: "../../assets/menu/close.png";
$home-img-path: "../../assets/images/banner.jpg";

// BREAKPOINTS
$breakpoints: (
    small: 600px,
    medium: 991px,
);
