@mixin showElementMixin($seconds) {
    animation-name: showElement;
    animation-duration: $seconds;

    @keyframes showElement {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
