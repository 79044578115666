@import "../base/variables";

/* COPYRIGHT footer */

.copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $black-color;
    color: $light-color;
    text-align: center;
    padding: 10px;

    &__author {
        min-width: 80vw;
    }

    &__source {
        margin-right: 2em;
    }

    .source__icon {
        width: 2em;
        height: 2em;
    }
}
