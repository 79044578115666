@import "../base/variables";

/* Portfolio WORK Block */

.work {
    &__heading p {
        padding: 10px;
        border-radius: 10px;
        background: $light-color;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

/* Work box */

.work-box {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--more {
        max-width: 33%;
    }

    @media (max-width: map-get($map: $breakpoints, $key: medium)) {
        width: 100%;
        padding: 10px;
    }

    &__title {
        border: 0;
        position: relative;
        background: $orange-color;
        display: inline-block;
        color: $light-color;
        margin-top: 20px;
        padding: 10px 30px;
        border-radius: 10px 10px 0 0;
        font-size: 18px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 2px;
        font-weight: 500;
    }

    &__image {
        max-width: 100%;
        transition: 0.2s;
        background: $green-color;
        border-radius: 10px;

        &:hover {
            filter: brightness(80%);
        }

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            max-width: 60vw;
        }
    }

    &__description {
        position: relative;
        background: $red-color;
        display: inline-block;
        color: $light-color;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        font-weight: normal;

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            display: none;
        }
    }

    &__buttons {
        display: flex;
    }

    @include showElementMixin(2s);
}
