@import "../base/variables";

/* Site HEADER */

.site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 100px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    transition: 0.5s;

    &__toggle {
        display: none;
    }

    @media (max-width: map-get($map: $breakpoints, $key: medium)) {
        padding: 20px 50px;

        &__toggle {
            display: block;
            width: 2em !important;
            height: 2em;
            color: $light-color;
            cursor: pointer;
        }
    }

    @media (max-width: map-get($map: $breakpoints, $key: small)) {
        padding: 20px;
    }

    &__logo {
        color: $light-color;
        font-size: 24px;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 700;
        letter-spacing: 2px;
    }

    &.sticky {
        background: $light-color;
        padding: 20px 100px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

        @media (max-width: map-get($map: $breakpoints, $key: medium)) {
            padding: 20px 50px;

            & .site-header__toggle {
                color: $dark-color;
                width: 2em;
                height: 2em;
            }
        }

        @media (max-width: map-get($map: $breakpoints, $key: small)) {
            padding: 20px;
        }

        & .site-header__logo {
            color: $dark-color;
        }
    }
}
