@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

/* Primary RESET */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", sans-serif;
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    color: $main-color;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

a:hover {
    filter: brightness(120%);
    transition: all 0.3s ease-in-out;
}

p {
    letter-spacing: 2px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
    html,
    body {
        width: 100%;
        overflow-x: hidden;
    }
}
